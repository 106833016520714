import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import clinicImage from '../assets/clinicImage.jpg'
import Footer from '../components/Footer'
import { AutoComplete, Select } from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Clinic = () => {
  const [clinics, setClinics] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [searchFor, setSearchFor] = useState('')
  const [near, setNear] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const navigate = useNavigate()
  const [appliedFilters, setAppliedFilters] = useState([])
  const [totalClinics, setTotalClinics] = useState(0)

  const [checkboxes, setCheckboxes] = useState({
    specialties: [],
    states: []
  })

  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [selectedStates, setSelectedStates] = useState([])

  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)

  const [isSortByName, setIsSortByName] = useState(true)

  const toggleSort = () => {
    setIsSortByName(!isSortByName)
  }

  const toggleAdditionalOptions = () => {
    setShowAdditionalOptions(!showAdditionalOptions)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/module/clinics`, {
          params: {
            page,
            pageSize,
            // searchFor,
            // near,
            specialties: selectedSpecialties.join(','),
            states: selectedStates.join(','),
            sort: isSortByName ? 'name' : 'createdAt' // Add sorting parameters
          }
        })
        setClinics(response.data)
        setTotalClinics(response.data.length)
      } catch (error) {
        console.error('Error fetching clinics:', error)
      }
    }

    fetchData()
  }, [page, pageSize, selectedSpecialties, selectedStates, isSortByName])

  useEffect(() => {
    const fetchCheckboxData = async () => {
      try {
        const response = await axios.get(`/api/module/clinicList`)
        const { specialties, states } = response.data

        console.log(specialties.length)

        setCheckboxes({
          specialties,
          states
        })
      } catch (error) {
        console.error('Error fetching checkbox data:', error)
      }
    }

    fetchCheckboxData()
  }, [])

  const updateSelectedSpecialties = specialty => {
    const updatedFilters = [...appliedFilters]
    if (selectedSpecialties.includes(specialty)) {
      setSelectedSpecialties(
        selectedSpecialties.filter(item => item !== specialty)
      )
      setAppliedFilters(
        updatedFilters.filter(filter => filter !== `${specialty} `)
      )
    } else {
      setSelectedSpecialties([...selectedSpecialties, specialty])
      setAppliedFilters([...updatedFilters, `${specialty} `])
    }
  }

  const updateSelectedStates = state => {
    const updatedFilters = [...appliedFilters]
    if (selectedStates.includes(state)) {
      setSelectedStates(selectedStates.filter(item => item !== state))
      setAppliedFilters(updatedFilters.filter(filter => filter !== `${state} `))
    } else {
      setSelectedStates([...selectedStates, state])
      setAppliedFilters([...updatedFilters, `${state} `])
    }
  }

  const handlePageSizeChange = value => {
    setPageSize(value)
    setPage(1)
  }

  const handleSearch = async () => {
    try {
      const response = await axios.get(`/api/module/clinics`, {
        params: {
          page: 1, // Reset page to 1
          pageSize,
          searchFor,
          near
        }
      })
      setClinics(response.data)
      setTotalClinics(response.data.length)
    } catch (error) {
      console.error('Error fetching clinics:', error)
    }
  }

  const handleNearSearch = async value => {
    try {
      const response = await axios.get(
        `/api/module/clinicStates?startsWith=${value}`
      )
      setSuggestions(response.data)
    } catch (error) {
      console.error('Error fetching clinic states:', error)
    }
  }

  const [isSecondView, setIsSecondView] = useState(false)

  const handleButtonClick = () => {
    setIsSecondView(!isSecondView)
  }

  const clinicListClass = isSecondView ? 'clinic-list-3' : 'clinic-list'
  const clinicClass = isSecondView ? 'clinic-2' : 'clinic'

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="two-column-container purple-background">
          <div className="text-white">
            <div className="text-background p-3">
              <p style={{ fontSize: '25px', margin: 0 }}>Clinic Directory</p>
              <p>Dear Parents,</p>
              <p>
                Welcome to IMFeD Online Clinic Directory! IMFeD for Growth is an
                initiative by the Malaysian Paediatric Association, which aims
                to help our children achieve their potential growth.
              </p>
              <p>
                Discuss your child’s growth today! Scroll down to find your
                nearest IMFeD doctor.
              </p>
            </div>
          </div>
          <div>
            <img src={clinicImage} className="w-100" alt="About Us" />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '35px',
            flexWrap: 'wrap'
          }}
        >
          <div style={{ width: '50%' }}>
            <input
              type="text"
              placeholder="Search for..."
              value={searchFor}
              onChange={e => setSearchFor(e.target.value)}
              style={{
                marginRight: '0',
                height: '40px',
                borderRadius: '7px',
                border: '1px solid #ccc'
              }}
            />
          </div>
          <div className="d-flex">
            <div>
              <AutoComplete
                placeholder="Near..."
                onChange={value => setNear(value)}
                onSearch={handleNearSearch}
                className="autocomplete-text"
              >
                {suggestions.map(suggestion => (
                  <AutoComplete.Option key={suggestion} value={suggestion}>
                    {suggestion}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </div>
            <div>
              <button
                className="square-button"
                onClick={handleSearch}
                style={{ height: '40px' }}
              >
                SEARCH
              </button>
            </div>
          </div>
        </div>

        {appliedFilters.length > 0 && (
          <div
            style={{
              display: 'flex',
              margin: 'auto',
              marginBottom: '20px'
            }}
          >
            <div style={{ marginTop: '10px' }}>
              Applied filters:{' '}
              {appliedFilters.map((filter, index) => (
                <span style={{ cursor: 'pointer' }} key={index}>
                  {filter}
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mt-4">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="filter-border" onClick={toggleAdditionalOptions}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                className="icon-filter"
              >
                <path d="M496 384H160v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h80v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h336c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160h-80v-16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h336v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h80c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-160H288V48c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v16H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h208v16c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-16h208c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16z" />
              </svg>
              FILTER YOUR RESULT
            </p>
            <p style={{ padding: '5px 10px 5px 10px' }}>
              Showing {(page - 1) * pageSize + 1} -{' '}
              {Math.min(page * pageSize, totalClinics)} of {totalClinics}
            </p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div className="filter-border-2" onClick={handleButtonClick}>
              {isSecondView === false ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 512 512"
                  className="icon-filter-2"
                >
                  <path d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 512 512"
                  className="icon-filter-2"
                >
                  <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
                </svg>
              )}
            </div>

            <p className="filter-border" onClick={toggleSort}>
              {isSortByName ? 'SORT BY: NEWEST FIRST' : 'SORT BY: NAME'}
            </p>
          </div>
        </div>

        <div
          className={`additional-options ${
            showAdditionalOptions ? 'show' : 'hide'
          }`}
        >
          <table>
            <thead>
              <tr>
                <th>Speciality</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ display: 'flow' }}>
                  {checkboxes.specialties.map((specialty, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        checked={selectedSpecialties.includes(specialty)}
                        onChange={() => updateSelectedSpecialties(specialty)}
                      />
                      <span className="ms-2">{specialty} </span>
                    </div>
                  ))}
                </td>
                <td>
                  {checkboxes.states.map((state, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        checked={selectedStates.includes(state)}
                        onChange={() => updateSelectedStates(state)}
                      />
                      <span className="ms-2">{state}</span>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="two-column-container">
          <div className={clinicListClass}>
            {clinics.map(clinic => (
              <div className={clinicClass} key={clinic.id}>
                <h5
                  className="clinic-name"
                  onClick={() =>
                    navigate('/clinic-listing', { state: clinic.id })
                  }
                >
                  {clinic.DoctorName}
                </h5>
                <div style={{ display: 'flex', marginTop: '5px' }}>
                  <img
                    className="mdi-location"
                    alt="Mdi location"
                    src="https://c.animaapp.com/U4vrOscu/img/mdi-location.svg"
                  />
                  <p className="clinic-name">{clinic.Profession}</p>
                </div>
                <div style={{ display: 'flex' }}>
                  <img
                    className="img"
                    alt="Mdi location"
                    src="https://c.animaapp.com/U4vrOscu/img/mdi-location-1.svg"
                  />
                  <p style={{ margin: 0 }}>{clinic.ClinicAddress}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.2182549188567!2d101.63072491444664!3d3.1174905976861044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc48419dbf0429%3A0xe40ab8d4b647b9a4!2sThe%20Suites%20Jaya%20One!5e0!3m2!1sen!2smy!4v1642626761947!5m2!1sen!2smy"
              width="400"
              height="500"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
            gap: '20px'
          }}
        >
          <div>
            <span>Show </span>
            <Select defaultValue={pageSize} onChange={handlePageSizeChange}>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={50}>50</Select.Option>
            </Select>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{ margin: 0, cursor: 'pointer' }}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              Prev
            </p>
            <span style={{ margin: '0 10px' }}>
              Page {page} of {Math.ceil(clinics.length / pageSize)}
            </span>
            <p
              style={{ margin: 0, cursor: 'pointer' }}
              onClick={() => setPage(page + 1)}
              disabled={clinics.length < pageSize}
            >
              Next
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Clinic
